import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Col,
  Row,
  Modal,
  Radio,
  Tex,
  Divider,
} from "antd";
import { retrieveItem } from "@helpers/storage";
import moment from "moment";
import {
  dynamicRequest,
  submit_application_query,
  useDynamicSelector,
} from "@services/redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import toast from "react-hot-toast";
import Pass from "@views/pages/pass";
import ReactHtmlParser from "html-react-parser";

const { Option } = Select;
const ApplyEPass = () => {
  const { id } = useParams();
  const regex = /^[a-zA-Z0-9\s]*$/; // Regex to allow numbers, alphabets, and spaces
  const dispatch = useDispatch();
  let terms = retrieveItem("general_settings")?.pass_terms;
  const [model_open, set_model_open] = useState(false);
  const [confirmation_model_open, set_confirmation_model_open] =
    useState(false);
  const [validFromDate, setValidFromDate] = useState(null);
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const countries = retrieveItem("countries");
  const vehicle_types = retrieveItem("vehicle_types");
  const purpose_of_visit = retrieveItem("reasons");
  const foreign = retrieveItem("foreign");
  const general_settings = retrieveItem("general_settings");
  const fuelTypes = [
    { id: "petrol", name: "Petrol" },
    { id: "diesel", name: "Diesel" },
    { id: "cng", name: "CNG" },
    { id: "electric", name: "Electric" },
    { id: "hybrid", name: "Hybrid" },
  ];
  const [states, set_states] = useState([]);
  const [districts, set_districts] = useState([]);
  const [place_of_stay, set_place_of_stay] = useState("");
  const [reason, set_reason] = useState(false);

  const {
    id: application_id,
    error,
    status,
    loading,
  } = useDynamicSelector("submit_application");

  useEffect(() => {
    if (foreign === "false") {
      form.setFieldsValue({ country_id: countries?.[0]?.id });
      let states = countries?.[0]?.states;
      set_states(states || []);
      form.setFieldsValue({ state_id: states?.[0]?.id });
      set_districts(states?.[0]?.districts || []);
    }
  }, []);

  useEffect(() => {
    if (application_id) {
      // navigate(ROUTES.DASHBOARD);
      handle_confirmation_cancel();
      set_model_open(true);
    } else if (status === "failure") {
      toast.error("Something went wrong, Please try again!");
    }
  }, [application_id]);

  const onChange = (val, values) => {
    if (val.country_id) {
      let states = countries.find((x) => x.id === val.country_id)?.states;
      set_states(states || []);
      set_districts(states?.[0]?.districts || []);
      form.setFieldsValue({
        state_id: states?.[0]?.id || "",
        district_id: "",
      });
    }
    if (val.state_id) {
      let districts = states.find((x) => x.id === val.state_id)?.districts;
      set_districts(districts || []);
      form.setFieldsValue({
        district_id: "",
      });
    }
    if (val.know_the_place_of_stay) {
      set_place_of_stay(val.know_the_place_of_stay);
    }
    if (val.reason_id) {
      let reason = purpose_of_visit.find(
        (x) =>
          x.id === val.reason_id &&
          (x.name === "Agri Commodity " || x.name === "Essential Commodity ")
      );
      if (reason?.id) {
        set_reason(true);
        form.setFieldsValue({ valid_till: moment("2024-10-31").endOf("day") });
      } else {
        set_reason(false);
        form.setFieldsValue({ valid_till: null });
      }
    }
  };
  const onFinish = (values) => {
    setData(values);
    set_confirmation_model_open(true);
  };
  const submit_application = () => {
    let values = data;
    const trimmedValue = values.vehicle_number.trim().replace(/ /g, "");
    let submit_application_key = [{ key: "submit_application", loading: true }];
    let submit_application_variables = {
      data: {
        ...values,
        vehicle_number: trimmedValue,
        destination_id: id,
        pincode: String(values.pincode),
        vehicle_manufactured_year: String(values.vehicle_manufactured_year),
        know_the_place_of_stay:
          values.know_the_place_of_stay === "yes" ? true : false,
      },
    };
    dispatch(
      dynamicRequest(
        submit_application_key,
        submit_application_query,
        submit_application_variables,
        "M"
      )
    );
  };
  const disabledDates = (current) => {
    return (
      current &&
      (current <
        (moment(general_settings.active_from).subtract(1, "day").endOf("day") &&
          moment().subtract(1, "day").endOf("day")) ||
        current > moment("2024-10-31").endOf("day"))
    );
  };
  const disabledDatesexit = (current) => {
    // Disable dates after the selected "Date of Entry"
    return (
      current <
        moment(general_settings.active_from).subtract(1, "day").endOf("day") ||
      current > moment("2024-10-31").endOf("day") ||
      (validFromDate && current && current < validFromDate.startOf("day"))
    );
  };

  const handleValidFromDateChange = (date) => {
    setValidFromDate(date);
  };

  const handle_cancel = () => {
    set_model_open(false);
    navigate(ROUTES.DASHBOARD);
  };
  const handle_confirmation_cancel = () => {
    set_confirmation_model_open(false);
  };
  const options = [
    {
      label: "தங்கும் இடம் எங்களுக்கு தெரியும் / I know my place of stay",
      value: "yes",
    },
    {
      label: "தங்கும் இடம் இன்னும் தெரியாது  / I don't know my place of stay",
      value: "no",
    },
  ];
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "23px",
            backgroundColor: "ButtonHighlight",
            width: "300px",
            padding: "5px",
          }}
        >
          TN ePass Application
        </div>
      </div>

      <br />
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onValuesChange={onChange}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="name"
              label="விண்ணப்பதாரர் பெயர் / Applicant Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your applicant name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            md={12}
            lg={8}
            style={{ display: "flex", alignItems: "end" }}
          >
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="reason_id"
              label="வருகையின் காரணம் / Purpose of Visit"
              rules={[
                { required: true, message: "Please enter purpose of visit!" },
              ]}
            >
              <Select size="large">
                {purpose_of_visit?.map((purpose) => (
                  <Option key={purpose.id} value={purpose.id}>
                    {purpose.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              name="vehicle_number"
              label="வாகன பதிவு எண் / Vehicle Registration Number"
              rules={[
                {
                  required: true,
                  message: "Please enter your vehicle registration number",
                },
                {
                  pattern: regex,
                  message: "Only numbers, alphabets, and spaces are allowed!",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input style={{ textTransform: "uppercase" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="total_passengers"
              label="மொத்த பயணிகளின் எண்ணிக்கை / Number of Passengers"
              rules={[
                {
                  required: true,
                  message: "Please enter number of passengers!",
                },
                {
                  type: "number",
                  max: 60,
                  message: "Number must be less than or equal to 60!",
                },
              ]}
            >
              <InputNumber
                size="middle"
                changeOnWheel={false}
                controls={false}
                min={1}
                max={60}
                maxLength={2}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="vehicle_manufactured_year"
              label="வாகன உற்பத்தி வருடம் / Year of Manufacture"
              rules={[
                {
                  required: true,
                  message: "Please enter year of manufacture!",
                },
              ]}
            >
              <InputNumber changeOnWheel={false} controls={false} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="vehicle_type_id"
              label="வாகன வகை / Vehicle Type"
              rules={[
                { required: true, message: "Please enter your vehicle type" },
              ]}
            >
              <Select size="large">
                {vehicle_types?.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="vehicle_fuel_type"
              label="எரிபொருள் வகை / Fuel Type"
              rules={[{ required: true, message: "Please enter fuel type!" }]}
            >
              <Select size="large">
                {fuelTypes?.map((fuelType) => (
                  <Option key={fuelType.id} value={fuelType.id}>
                    {fuelType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="valid_from"
              label="உள் நுழையும் நாள் / Date of Entry"
              rules={[
                { required: true, message: "Please enter your date of entry!" },
              ]}
            >
              <DatePicker
                onChange={handleValidFromDateChange}
                disabledDate={disabledDates}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="valid_till"
              label="வெளியேறும் நாள் / Date of Exit"
              rules={[
                {
                  required: true,
                  message: "Please enter your date of exit!",
                  // validator: (_, value) => {
                  //   if (!value || value.isAfter(validFromDate)) {
                  //     return Promise.resolve();
                  //   }
                  //   return Promise.reject(
                  //     new Error("Exit date must be after entry date!")
                  //   );
                  // },
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const validFrom = getFieldValue("valid_from");

                    if (
                      value &&
                      validFrom &&
                      value.valueOf() <= validFrom.valueOf()
                    ) {
                      if (value.isSame(validFrom, "day")) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Valid Till should not be earlier than Valid From"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                disabled={reason}
                disabledDate={disabledDatesexit}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="country_id"
              label="நாடு / Country"
              rules={[{ required: true, message: "Please enter country!" }]}
            >
              <Select
                disabled={foreign !== "true"}
                size="large"
                defaultActiveFirstOption={true}
              >
                {foreign === "true"
                  ? countries?.slice(1)?.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))
                  : countries?.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>

          {states?.length ? (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="state_id"
                label="மாநிலம் / State"
                rules={[
                  {
                    required: foreign === "true" ? false : true,
                    message: "Please enter state!",
                  },
                ]}
              >
                <Select
                  disabled={foreign === "true"}
                  size="large"
                  defaultActiveFirstOption={true}
                >
                  {states?.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          {districts?.length ? (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="district_id"
                label="மாவட்டம் / District"
                rules={[
                  {
                    required: foreign === "true" ? false : true,
                    message: "Please enter district!",
                  },
                ]}
              >
                <Select
                  disabled={foreign === "true"}
                  size="large"
                  defaultActiveFirstOption={true}
                >
                  {districts?.map((district) => (
                    <Option key={district.id} value={district.id}>
                      {district.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="from_address_1"
              label="முகவரி 1 / Address Line 1"
              rules={[
                { required: true, message: "Please enter address line 1!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="from_address_2"
              label="முகவரி 2 / Address Line 2"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            {foreign === "true" ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="pincode"
                label="அஞ்சல் குறியீ டு / Zipcode"
                rules={[{ required: true, message: "Please enter zipcode!" }]}
              >
                <InputNumber changeOnWheel={false} controls={false} />
              </Form.Item>
            ) : (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="pincode"
                label="அஞ்சல் குறியீ டு / Pincode"
                rules={[
                  { required: true, message: "Please enter pincode!" },
                  {
                    pattern: /^[0-9]{6}$/, // Regular expression for 6-digit pincode
                    message: "Please enter a valid 6-digit pincode!",
                  },
                ]}
              >
                <InputNumber changeOnWheel={false} controls={false} />
              </Form.Item>
            )}
          </Col>

          <Col xs={24} md={24} lg={24}>
            <Form.Item
              rules={[{ required: true, message: "Please choose Stay" }]}
              labelCol={{ span: 24 }}
              name="know_the_place_of_stay"
              wrapperCol={{ span: 24 }}
            >
              <Radio.Group options={options} />
            </Form.Item>
          </Col>
          {place_of_stay === "yes" ? (
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="destination_address"
                label="தங்கும் இடத்தின் முகவரி  / Address of Stay"
                rules={[
                  { required: true, message: "Please enter address of stay!" },
                ]}
              >
                <Input changeOnWheel={false} controls={false} />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#346732" }}
              loading={loading}
            >
              சமர்ப்பிக்க / Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        title={"Download Your Pass"}
        open={model_open}
        width={1000}
        // onOk={handle_ok}
        onCancel={handle_cancel}
        footer={null}
      >
        <Pass id={application_id} />
      </Modal>
      <Modal
        title={"Confirmation"}
        open={confirmation_model_open}
        width={1000}
        // onOk={handle_ok}
        onCancel={handle_confirmation_cancel}
        footer={null}
      >
        <Divider />
        <div className="text-start text-red">
          கீழே உள்ள நிபந்தனைகளையும், பிற சட்டங்கள், விதிகள் மற்றும்
          விதிமுறைகளையும் பின்பற்றுவதாக ஒப்புதல் அளிக்கிறேன். ஏதாவது மீறல்களில்
          ஈடுபட்டால், நான் சட்டப்படி தண்டிக்கப்படுவேன் என்று உணர்ந்துள்ளேன்.
          <br />
          By clicking on confirm, I hereby agree to abide by the conditions
          given below and any other laws, rule and regulations applicable. I
          will be liable to punishment according to law in case of any
          violations.
          <br />
          <br />
        </div>
        {ReactHtmlParser(terms || " ")}
        <Divider />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handle_confirmation_cancel}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            variant="contained"
            onClick={submit_application}
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ApplyEPass;
